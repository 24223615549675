import type { Event } from "src/api/AnalyticsApi/AnalyticsApi";
import { AnalyticsApi } from "src/api/AnalyticsApi/AnalyticsApi";
import type { Metric } from "web-vitals";
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from "web-vitals";
export interface Input {
  uid: string;
  aqid: string;
}
static const eventQueue: Event[] = [];

<const/trackingIds={
  uid: input.uid,
  aqid: input.aqid,
  requestId: $global.r2rUserContext.requestId,
} />

<lifecycle
  onMount() {
    const callback = ({ name, delta, id }: Metric) => {
      // Metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      const eventValue = Math.round(name === "CLS" ? delta * 1000 : delta);
      eventQueue.push({ name, id, eventValue });
    };
    onCLS(callback);
    onFID(callback);
    onLCP(callback);
    onTTFB(callback);
    onFCP(callback);
    onINP(callback);
  }
/>

<effect() {
  function callback() {
    if (document.visibilityState === "hidden") {
      AnalyticsApi.logCoreWebVitalList(
        eventQueue,
        trackingIds,
        $global.clientApiHost,
      );
      eventQueue.length = 0;
    }
  }
  window.addEventListener("visibilitychange", callback);
  return () => window.removeEventListener("visibilitychange", callback);
} />

<effect() {
  function callback() {
    AnalyticsApi.logCoreWebVitalList(
      eventQueue,
      trackingIds,
      $global.clientApiHost,
    );
    eventQueue.length = 0;
  }
  window.addEventListener("pagehide", callback);
  return () => window.removeEventListener("pagehide", callback);
} />
