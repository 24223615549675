export type Event = {
  name: string;
  id: string;
  eventValue: number;
};

type Exit = {
  category: string;
  action: string;
  label?: string;
  value?: string;
  currentUrl: string;
  destinationUrl: string;
  originCanonical?: string;
  destinationCanonical?: string;
  provider?: string;
  agencyIds?: string[];
  rawExitPoint: string;
};

type TrackingIdentifier = {
  uid: string;
  aqid: string;
  requestId: string;
};

export const NON_COMMERCIAL_PROVIDER = "NonCommercial";

export const AnalyticsApi = {
  logCoreWebVitalList(
    eventList: Event[],
    tracking: TrackingIdentifier,
    hostname: string,
  ): void {
    if (eventList.length === 0) {
      return;
    }

    if (import.meta.env.DEV) {
      // Console log CWV in DEV so we don't pollute our data with garbage values
      // from unoptimized dev builds.
      console.log(
        "Log CoreWebVitals:\n" +
          eventList.map((e) => ` - ${e.name}: ${e.eventValue}`).join("\n"),
      );
      return;
    }

    if (!tracking.aqid || !tracking.uid) {
      console.error("Missing aqid/uid/requestId.");
      return;
    }

    const urlBuilder = new URL("/api/json/LogCoreWebVitals", hostname);
    urlBuilder.searchParams.set("uid", tracking.uid);
    urlBuilder.searchParams.set("aqid", tracking.aqid);
    urlBuilder.searchParams.set("requestId", tracking.requestId);
    const url = urlBuilder.href;

    const body = JSON.stringify({
      payload: eventList,
      requestId: tracking.requestId,
    });

    // If the browser doesn't support sendBeacon, or sendBeacon failed for some reason, fallback to using a regular POST request.
    if (typeof navigator?.sendBeacon !== "function") {
      this.sendWithFetch(url, body);
      return;
    }
    try {
      const headers = {
        type: "application/json",
      };
      const blob = new Blob([body], headers);
      navigator.sendBeacon(url, blob);
    } catch (error) {
      this.sendWithFetch(url, body);
    }
  },

  logExit(exit: Exit, tracking: TrackingIdentifier, hostname: string): void {
    const urlBuilder = new URL("/api/json/LogExit", hostname);
    urlBuilder.searchParams.set("uid", tracking.uid);
    urlBuilder.searchParams.set("aqid", tracking.aqid);
    urlBuilder.searchParams.set("requestId", tracking.requestId);
    const url = urlBuilder.href;

    const body = JSON.stringify(exit);

    // If the browser doesn't support sendBeacon, or sendBeacon failed for some reason, fallback to using a regular POST request.
    if (typeof navigator?.sendBeacon !== "function") {
      this.sendWithFetch(url, body);
      return;
    }
    try {
      const headers = {
        type: "application/json",
      };
      const blob = new Blob([body], headers);
      navigator.sendBeacon(url, blob);
    } catch (error) {
      this.sendWithFetch(url, body);
    }
  },

  sendWithFetch(url: string, body: string): void {
    // The browser doesn't support sendBeacon, or sendBeacon failed for some reason, so
    // fallback to using a regular POST request.

    // We're deliberately leaving this Promise hanging, because there is no way
    // for us to cleanly handle it.
    fetch(url, {
      method: "POST",
      // We'll be sending multiple events to this endpoint, so keep the connection alive.
      keepalive: true,
      body,
    }).catch((error: Error) => {
      window.Sentry?.captureException("AnalyticsApi sendWithFetch failed", {
        syntheticException: error,
      });
    });
  },
};
