import type { Trigger } from "src/analytics/generateExitPoint/compressTrigger";
import type { AnalyticsEvent } from "src/analytics/sendEvent";
import { sendEvent } from "src/analytics/sendEvent";
import { buildHotelsRedirectUrl } from "./urlSelector";
import type { LanguageCode } from "src/utils/language";
import Cookies from "js-cookie";
import { getCookie } from "src/utils/getCookie";
import {
  clearPopunderCookie,
  getIsPopunderRateLimited,
  updateCookie,
} from "./popunderCookie";

// Global state for popunders which is per-page, so that we don't need to
// pass these values around to tags deep in the tree. We may want to use
// a Context for this instead because it is available at SSR time.
let pageOriginCanonical: string | undefined;
let pageDestinationCanonical: string | undefined;

/**
 * Sets up and enables popunders for the page, called indirectly through \<setup-popunder />.
 */
export function setupPopunder(options: {
  originCanonical: string;
  destinationCanonical: string;
}) {
  pageOriginCanonical = options.originCanonical;
  pageDestinationCanonical = options.destinationCanonical;
  if (typeof window !== "undefined") {
    window.isPopunderHydrated = true;
  }
}

interface PopunderOptions {
  originCanonicalName?: string;
  destinationCanonicalName?: string;
  requestId: string;
  popunderProvider: string | undefined;
  languageCode: LanguageCode;
  isRateLimitEnabled: boolean;
  view: string;
  trigger: Trigger;
  eventId: string;
}

/**
 * Navigates to the Explore product with a popunder.
 *
 * Instead of using the directly you should use \<popunder-link /> which will handle this for you.
 *
 * @param intendedDestinationUrl The URL the user intended to navigate to.
 * @param options The data needed to perform the popunder.
 * @returns A tuple of [didAttemptPopunder, destinationUrl] where destinationUrl is the URL the user intended to navigate to.
 */
export function navigateToExplore(
  intendedDestinationUrl: string,
  options: PopunderOptions,
): [true, string] | [false] {
  const isEligibleForPopunder = selectPopunderEligibility(
    undefined,
    options.isRateLimitEnabled,
  );
  if (isEligibleForPopunder !== "Enabled") {
    if (import.meta.env.DEV) {
      console.info(
        `User is not eligible for a popunder: ${isEligibleForPopunder}`,
      );
    }
    // The user is not eligible for a popunder, so return early.
    return [false];
  }

  const originCanonicalName =
    options.originCanonicalName ?? pageOriginCanonical;
  const destinationCanonicalName =
    options.destinationCanonicalName ?? pageDestinationCanonical;
  if (!destinationCanonicalName) {
    window.Sentry?.captureMessage("Missing destination for popunder");
    // We can't popunder if the origin or destination is missing.
    return [false];
  }

  // The URL we want to leave-behind in the users' browser.
  updateCookie(options.popunderProvider ?? "Unknown");
  const popunderUrl = buildHotelsRedirectUrl({
    trigger: options.trigger,
    view: options.view,
    requestId: options.requestId,
    hotelProvider: options.popunderProvider,
    originCanonicalName,
    destinationCanonicalName,
    languageCode: options.languageCode,
    navigationEventId: options.eventId,
  });
  triggerPopunder({
    popunderUrl,
    destinationUrl: intendedDestinationUrl,
    successEvent: {
      category: "Hotel",
      action: "LandingPopunder:Displayed",
      label: options.popunderProvider ?? "UnsuppliedProvider",
      isNonInteraction: true,
    },
    failureEvent: {
      category: "Hotel",
      action: "Redirect:Failed",
      label: options.popunderProvider ?? "UnsuppliedProvider",
      isNonInteraction: true,
    },
  });
  return [true, popunderUrl];
}

/**
 * Triggers a popunder/leave-behind avoiding common pop-up blocking techniques.
 *
 * @param popunderUrl The URL we want to leave-behind in the users' browser.
 * @param destinationUrl The URL the user intended to go to / popoverUrl.
 */
function triggerPopunder({
  popunderUrl,
  destinationUrl,
  successEvent,
  failureEvent,
}: {
  popunderUrl: string;
  destinationUrl: string;
  successEvent: AnalyticsEvent;
  failureEvent: AnalyticsEvent;
}) {
  // Try and open the destinationUrl in a new tab.
  const popoverWindow = window.open(destinationUrl, "_blank");

  if (!popoverWindow) {
    // We couldn't open a popover, so just update the current location
    // and early return.
    sendEvent(failureEvent);
    window.location.href = destinationUrl;
    return;
  }

  try {
    // Some pop-up blockers work by overriding window.open and causing .focus() to error.
    // So, we try and focus the popover window. This will throw an error if a pop-up blocker
    // has overwridden the focus method.
    popoverWindow.focus();

    // Other pop-up blockers open the new window then immediately close it.
    // So, we wait and check to see if the tab was closed.
    setTimeout(() => {
      const popoverBlocked = popoverWindow.closed;
      if (popoverBlocked) {
        // Navigate the current tab to the popoverUrl because the new tab
        // was blocked.
        sendEvent(failureEvent);
        window.location.href = destinationUrl;
      } else {
        // Show the popunder because we were able to successfully open the destination
        // in a new tab and focused it.
        sendEvent(successEvent);
        window.location.href = popunderUrl;
      }
    }, 150);
  } catch (e) {
    sendEvent(failureEvent);
    // Something went wrong trying to open the destination URL in a new tab.
    window.location.href = destinationUrl;
  }
}

export type PopunderEligibility = "Enabled" | "UserSettingOff" | "RateLimit";

/**
 * Selector that returns "Enabled" if the popunder is eligible to be shown or
 * the reason it is ineligble to be shown.
 *
 * @param request Request object to use for cookie lookup, or undefined on the browser.
 */
export function selectPopunderEligibility(
  request?: Request,
  isRateLimitEnabled = true,
): PopunderEligibility {
  if (isRateLimitEnabled && getIsPopunderRateLimited(request)) {
    return "RateLimit";
  }

  if (!getPopunderUserPreference(request)) {
    return "UserSettingOff";
  }

  return "Enabled";
}

// For backwards compatibility with the existing landing pages, we need to use
// the HpuUserSetting cookie (even though it doesn't just impact Hotels).
const USER_SETTING_COOKIE = "HpuUserSetting";
const USER_SETTING_FALSE = "off";
const USER_SETTING_TRUE = "on";

/**
 * Returns whether or not the user has made a choice as to whether they should
 * be eligible for popunders.
 *
 * If the user has not made a choice either way, it will assume the user is eligible.
 */
export function getPopunderUserPreference(request?: Request): boolean {
  const userSetting = getCookie(USER_SETTING_COOKIE, request);
  // If the user doesn't have the USER_SETTING cookie, we assume they are eligible
  // and want to see the popunder.
  return userSetting === USER_SETTING_FALSE ? false : true;
}

export function setPopunderUserPreference(isEnabled: boolean) {
  const tenYearsInDays = 10 * 365;
  Cookies.set(
    USER_SETTING_COOKIE,
    isEnabled ? USER_SETTING_TRUE : USER_SETTING_FALSE,
    { expires: tenYearsInDays },
  );
  // Wipe the rate limit cookie if the user wants to explicitly enable the popup
  if (isEnabled) {
    clearPopunderCookie();
  }
}
